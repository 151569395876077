import React, { useEffect } from "react";
import "./terms-conditions-page.scss";
import LandingFooter from "../../components/landing-footer/LandingFooter"
import TermsConditionsFirstBlock from "./TermsConditionsFirstBlock";
import TermsConditionsBlock from "./TermsConditionsBlock";
import StartDomiciliationBlock from "../../components/addresses-page-components/StartDomiciliationBlock";

const TermsConditionsPage = (props : any) => {
  useEffect(() => {
    document.title = `Mentions légales | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
  })
  return (
      <div className="terms-conditions-page">
        <TermsConditionsFirstBlock/>
        <TermsConditionsBlock/>
        <StartDomiciliationBlock/>
        <LandingFooter/>
      </div>
  );
};

export default TermsConditionsPage;
