import React, { useEffect } from "react";
import "./service-page.scss";
import LandingFooter from "../../../components/landing-footer/LandingFooter"
import FAQBlock from "./FAQBlock";
import ServiceFirstBlock from "./ServiceFirstBlock";
import ServiceSecondBlock from "./ServiceSecondBlock";
import GetYourHeadOffice from "../../../components/addresses-page-components/GetYourHeadOffice";
import StartDomiciliationBlock from "../../../components/addresses-page-components/StartDomiciliationBlock";
import orangeEllipse from "../../../assets/png/Orange-Ellipse.png";

const ServicePage = () => {
  useEffect(() => {
    document.title = `Nos services | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://domicilier.tn/services`);
    }
  })
  return (
      <div className="service-page">
        <ServiceFirstBlock/>
        <ServiceSecondBlock/>
        <div className={"head-and-faq-block"}>
          <GetYourHeadOffice />
          <img src={orangeEllipse} alt="ellipse" />
          <FAQBlock/>
        </div>
        <StartDomiciliationBlock/>
        <LandingFooter/>
      </div>
  );
};

export default ServicePage;
