import React from "react";

const FlyingArrowMessageIcon = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.2845 0.186529C63.8418 -0.0660928 63.2974 -0.0618351 62.8589 0.197599L36.8511 15.5769C36.1764 15.9758 35.9528 16.8461 36.3518 17.5208C36.7506 18.1953 37.6208 18.4189 38.2957 18.0201L56.9559 6.98574L22.1983 43.05L4.91101 37.7612L27.4717 24.4205C28.1464 24.0214 28.3699 23.1511 27.971 22.4764C27.5722 21.8019 26.7018 21.5781 26.0271 21.9771L0.696913 36.9555C0.212958 37.2417 -0.0565529 37.7866 0.0100087 38.345C0.0765702 38.9035 0.466431 39.3699 1.00403 39.5343L21.6493 45.8505L30.7575 62.4288C30.767 62.446 30.7768 62.4605 30.7869 62.4734C30.9389 62.7269 31.1666 62.9339 31.4508 63.0534C31.6286 63.1282 31.8155 63.1647 32.0011 63.1647C32.3649 63.1647 32.723 63.0248 32.9933 62.7604L43.4663 52.5249L63.166 58.5518C63.3022 58.5934 63.442 58.614 63.5811 58.614C63.8814 58.614 64.178 58.5186 64.4253 58.3356C64.787 58.068 65.0005 57.6446 65.0005 57.1947V1.41927C65.0005 0.909623 64.7271 0.439009 64.2845 0.186529ZM30.8348 46.7251C30.6705 46.9626 30.5823 47.2444 30.5823 47.5332V56.2148L24.3629 44.8943L51.8846 16.3381L30.8348 46.7251ZM33.4208 58.374V49.4517L40.3736 51.5789L33.4208 58.374ZM62.162 55.2764L34.2745 46.7446L62.162 6.48646V55.2764Z"
        fill="white"
      />
    </svg>

  );
};

export default FlyingArrowMessageIcon;
