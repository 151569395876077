import React from "react";

const Youtube = () => {
    return (
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.15 4.95C18.6075 3.985 18.0188 3.8075 16.82 3.74C15.6225 3.65875 12.6112 3.625 10.0025 3.625C7.38875 3.625 4.37625 3.65875 3.18 3.73875C1.98375 3.8075 1.39375 3.98375 0.84625 4.95C0.2875 5.91375 0 7.57375 0 10.4963C0 10.4988 0 10.5 0 10.5C0 10.5025 0 10.5038 0 10.5038V10.5063C0 13.4163 0.2875 15.0887 0.84625 16.0425C1.39375 17.0075 1.9825 17.1825 3.17875 17.2638C4.37625 17.3338 7.38875 17.375 10.0025 17.375C12.6112 17.375 15.6225 17.3337 16.8212 17.265C18.02 17.1837 18.6087 17.0088 19.1513 16.0438C19.715 15.09 20 13.4175 20 10.5075C20 10.5075 20 10.5038 20 10.5013C20 10.5013 20 10.4988 20 10.4975C20 7.57375 19.715 5.91375 19.15 4.95ZM7.5 14.25V6.75L13.75 10.5L7.5 14.25Z" fill="white"/>
</svg>
    );
};

export default Youtube;
