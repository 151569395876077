import React, {useEffect, useState} from "react";
import { Modal } from 'antd';
import $ from "jquery";
import Stars from "../../assets/png/stars.png";

interface JobAppliedModelProps {
  visible: boolean,
  abort: any
}

const JobAppliedModel : React.FC<JobAppliedModelProps> = ({visible, abort}:any) => {
  const [VisibleModal, setVisibleModal] = useState(false);
  useEffect(() => {
    setVisibleModal(visible);
  }, [visible])
  useEffect(() => {
    if (VisibleModal) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [VisibleModal])
  const closeModal = () => {
    window.location.reload();
  }
  return (
    <Modal
      centered
      visible={VisibleModal}
      onCancel={closeModal}
      style={{ top: 20}}
      footer={<div></div>}
      wrapClassName={"job-applied-modal"}
      width={"50%"}
    >
      <div className={"job-applied-modal-content"} >
        <h2>Félicitations! <span>Votre candidature a été envoyée avec succès !</span></h2>
        <img src={Stars} alt="success" style={{display: "block", margin: "auto", width: "100%"}}/>
        <p>Vous serez contacté(e) dans les plus brefs délais pour un entretien. <br /> <strong>Bonne chance ! </strong></p>
      </div>
    </Modal>
  );
}
export default JobAppliedModel;
