import React from "react";
import { Link } from "react-router-dom";
import Place from "../../svg-components/icons/Place";

interface AddressCardPropsTypes {
  addressData: any
}

const AddressCard: React.FC<AddressCardPropsTypes> = ({ addressData }) => {
  return (
    <div className={"address-card-wrapper"}>
      <div>
        {addressData.promo ? (
          <div className={"address-promo"}>
            <span>Promo !</span>
            <span>-{addressData.promo} %</span>
          </div>
        ) : null}
        <div className={"address-card-header"} style={{ backgroundImage: `url('${process.env.REACT_APP_STORAGE_URL}${addressData.main_picture_url}')` }}></div>
        <Link to={`/address/${addressData.slug}`} className={"address-card-location"}>
          <Place />
          <div>
            <h3 className={"city"}>Domiciliation à {addressData.city}</h3>
            <p className={"address_line"}>{addressData.full_location}, {addressData.zip_code}</p>
          </div>
        </Link>
        <div className={"address-card-body"}>
          <div className={"address-card-description"}>
            {addressData.description}
          </div>
        </div>
      </div>
      <div className={"address-card-footer"}>
        {addressData.from_price ? (
          <div className={"starting-price"}>
            A partir de <br />
            {addressData.from_price.toLocaleString('fr-FR', { minimumFractionDigits: 3 })} Dt/mois
          </div>
        ) : null}
        <Link to={`/address/${addressData.slug}`} className={"see-more-btn"}>En savoir plus</Link>
      </div>
    </div>
  );
};

export default AddressCard;
