import React from "react";

const BenefitsSupportSmallIcon = () => {
  return (
<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.46462 24.3216L8.34155 25.1782H9.207H11.25C11.6478 25.1782 12.0294 25.3363 12.3107 25.6176C12.592 25.8989 12.75 26.2804 12.75 26.6782V38.25C12.75 38.6478 12.592 39.0294 12.3107 39.3107C12.0294 39.592 11.6478 39.75 11.25 39.75H9C6.87913 39.75 5.25 38.1601 5.25 36.3217V27C5.25 15.0077 15.0077 5.25 27 5.25C38.9923 5.25 48.75 15.0077 48.75 27V36.3217C48.75 38.1601 47.1209 39.75 45 39.75H44.25V40.5C44.25 45.0493 40.5493 48.75 36 48.75H23.25V43.5H30.75V45V45.75H31.5H36C38.896 45.75 41.25 43.396 41.25 40.5V26.6782C41.25 26.2804 41.408 25.8989 41.6893 25.6176C41.9706 25.3363 42.3522 25.1782 42.75 25.1782H44.793H45.6585L45.5354 24.3216C44.2323 15.2519 36.4295 8.25 27 8.25C17.5705 8.25 9.76766 15.2519 8.46462 24.3216Z" fill="white" stroke="#3240FF" stroke-width="1.5"/>
</svg>

  );
};

export default BenefitsSupportSmallIcon;
