import React from "react";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";


const ArticleBlock = () => {
  const article = useSelector((state: RootState) => state.blogReducer.article);
  return (
    <>
    <div className={"article-block"} >
      <div className={"max-width-container"}>
        <div className={"article-body"} dangerouslySetInnerHTML={{__html: article && article.body ? article.body : null }}>

        </div>
      </div>
    </div>
    </>
  );
};

export default ArticleBlock;
