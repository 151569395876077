import React, {useEffect, useState} from "react";

interface CounterProps {
  endDate: string;
}

function doubleDigitsFormat(params: any) {
  if (params < 10)  params = '0'+params;
  return params;

}

const calculateTimeLeft = (expiryDate: any) => {
  let difference = +new Date(expiryDate) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      jours: (Math.floor(difference / (1000 * 60 * 60 * 24))),
      heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      secondes: Math.floor((difference / 1000) % 60)
  };
}

return timeLeft;

}
const Counter: React.FC<CounterProps> = ({endDate}) => {
  const [timeLeft, setTimeLeft] : [any, (time: any) => void] = useState<any>(calculateTimeLeft(endDate));
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);
    return () => clearTimeout(timer);
  });
  const timerComponents: any = [];

  Object.keys(timeLeft).forEach((interval: string) => {
    const param = timeLeft[interval];
    timerComponents.push(
      <span className="countdown-item" key={interval}>
        {doubleDigitsFormat(param)}
          <br />
        {interval}
      </span>
    );
  });
  return (
    <div className="countdown-wrapper">
        {timerComponents.length ? timerComponents : "Cette promotion a maintenant pris fin"}
    </div>
  )
};

export default Counter;
