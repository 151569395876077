import React, { useEffect } from "react";
import "./terms-of-use-page.scss";
import LandingFooter from "../../components/landing-footer/LandingFooter"
import TermsOfUseFirstBlock from "./TermsOfUseFirstBlock";
import TermsOfUseBlock from "./TermsOfUseBlock";
import StartDomiciliationBlock from "../../components/addresses-page-components/StartDomiciliationBlock";

const TermsOfUsePage = (props : any) => {
  useEffect(() => {
    document.title = `Conditions générales de vente et d’utilisation | Domiciliation et création d'entreprise en Tunisie`;
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
  })
  return (
      <div className="terms-of-use-page">
        <TermsOfUseFirstBlock/>
        <TermsOfUseBlock/>
        <StartDomiciliationBlock/>
        <LandingFooter/>
      </div>
  );
};

export default TermsOfUsePage;
