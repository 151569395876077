import React, { useEffect, useState } from "react";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { Button } from 'antd';
import ApplyModel from "../../components/job-offers-components/ApplyModel";
import JobAppliedModel from "../../components/job-offers-components/JobAppliedModel";

const JobOfferBlock = () => {
  const jobOffer = useSelector((state: RootState) => state.serivcesReducer.jobOffer);
  const applied = useSelector((state: RootState) => state.serivcesReducer.applied);
  const [applicationModal, setApplicationModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  useEffect(() => {
    if (applied) {
      setApplicationModal(false);
      setSuccessModal(true);
    }
  }, [applied])
  return (
    <>
      <div className={"job-offer-block"} >
        <div className={"max-width-container"}>
          <div className={"job-offer-body"} dangerouslySetInnerHTML={{ __html: jobOffer && jobOffer.body ? jobOffer.body : null }}>

          </div>
        </div>
      </div>
      <div className={"apply-now-block"}>
        <h4>Vous pensez être le profil idéal pour cette offre ?
          Postulez sans plus attendre et intégrez l’équipe de Domicilier.tn</h4>
        <Button onClick={() => setApplicationModal(true)} className="blue-btn">Postuler</Button>
      </div>
      <ApplyModel
        visible={applicationModal}
        abort={() => setApplicationModal(false)}
      />
      <JobAppliedModel
        visible={successModal}
        abort={() => setSuccessModal(false)}
      />
    </>
  );
};

export default JobOfferBlock;
