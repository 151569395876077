import React, { useEffect } from "react";
import "./career-page.scss";
import LandingFooter from "../../components/landing-footer/LandingFooter"
import CareerFirstBlock from "./CareerFirstBlock";
import JobOffersBlock from "./JobOffersBlock";
import JoinBlock from "./JoinBlock";

const CareerPage = () => {
  useEffect(() => {
    document.title = `Recrutement | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", "https://domicilier.tn/career");
    }
  })
  return (
    <div className="career-page">
      <CareerFirstBlock />
      <JoinBlock />
      <JobOffersBlock />
      <LandingFooter />
    </div>
  );
};

export default CareerPage;
