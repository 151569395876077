import React, { useEffect } from "react";
import "./domiciliation-page.scss";
import DomiciliationFirstBlock from "./DomiciliationFirstBlock";
import LandingFooter from "../../../components/landing-footer/LandingFooter"
import DomiciliationSecondBlock from "./DomiciliationSecondBlock";
import HowToBlock from "./HowToBlock";
import FAQBlock from "./FAQBlock";
import GetYourHeadOffice from "../../../components/addresses-page-components/GetYourHeadOffice";
import StartDomiciliationBlock from "../../../components/addresses-page-components/StartDomiciliationBlock";
import orangeEllipse from "../../../assets/png/Orange-Ellipse.png";

const DomiciliationPage = () => {
  useEffect(() => {
    document.title = `Domiciliation en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://domicilier.tn/services/domiciliation`);
    }
  })
  return (
      <div className="domiciliation-page">
        <DomiciliationFirstBlock/>
        <DomiciliationSecondBlock/>
        <HowToBlock/>
        <div className={"head-and-faq-block"}>
          <GetYourHeadOffice />
          <img src={orangeEllipse} alt="ellipse" />
          <FAQBlock/>
        </div>
        <StartDomiciliationBlock/>
        <LandingFooter/>
      </div>
  );
};

export default DomiciliationPage;
