import React from "react";

interface AdrItemLandingPropsTypes {
  title: string;
  description: string;
  btnvisible: boolean;
  btnText: string;
  icon: React.FunctionComponentElement<any>;
}
const GetYourHeadOfficeStep: React.FC<AdrItemLandingPropsTypes> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div className={"step-wrapper"}>
      <div className="header">
        {icon}
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  );
};

export default GetYourHeadOfficeStep;
