import React from "react";

const SNC = () => {
return (
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M60.3576 7.51562H3.64423C1.6399 7.51562 0 9.15553 0 11.1599V52.8408C0 54.7157 1.43947 56.2536 3.26523 56.4467V56.485H3.64423H9.18529V51.3612C8.89921 51.4651 8.59674 51.5288 8.27423 51.5288C6.76369 51.5288 5.54105 50.3044 5.54105 48.7957L5.54288 31.5311C5.54288 30.0206 6.76734 28.7979 8.27605 28.7979H14.1651L15.1946 30.1718L13.3033 40.884L15.8652 43.3002L18.4271 40.884L16.5357 30.1718L17.5652 28.7979H23.0024L31.2238 25.9646C32.6505 25.4708 34.2066 26.2306 34.6986 27.6573C35.1905 29.084 34.4325 30.6401 33.004 31.1321L24.3489 34.1167C24.0629 34.2133 23.7622 34.2661 23.4579 34.2661H22.5286C22.5305 34.3153 22.5432 34.3663 22.5432 34.4192V56.485H23.9845H43.0438H60.3539C62.3601 56.485 63.9982 54.8451 63.9982 52.8408V11.1599C64.0018 9.15189 62.3656 7.51562 60.3576 7.51562ZM15.867 26.9376C12.4323 26.9376 9.64993 22.6665 9.64993 18.749C9.64993 14.8296 12.4323 12.7524 15.867 12.7524C19.3017 12.7524 22.084 14.8296 22.084 18.749C22.084 22.6629 19.2999 26.9376 15.867 26.9376ZM60.9644 51.0095H27.104V49.9509V49.7085V37.7336H28.4031V49.7085H32.0364V36.9628H37.2659V49.7085H40.8719V30.8624H46.1032V49.7049H49.711V25.1337H45.6349L52.3239 18.4447L59.0147 25.1337H54.9404V49.7031H60.9644V51.0095Z" fill="#3240FF"/>
</svg>
);
};

export default SNC;
