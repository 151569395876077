import React, { useEffect } from "react";
import "./article-page.scss";
import { Skeleton } from 'antd';
import {
  useParams,
  Redirect
} from "react-router-dom";
import LandingFooter from "../../components/landing-footer/LandingFooter"
import { getArticle } from "../../actions/blog-actions/actions";
import StartDomiciliationBlock from "../../components/addresses-page-components/StartDomiciliationBlock";
import ArticleFirstBlock from "./ArticleFirstBlock";
import ArticleBlock from "./ArticleBlock";
import { RootState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import orangeEllipse from "../../assets/png/Orange-Ellipse.png";

const ArticlePage = ({ history }: any) => {
  let { slug }: any = useParams();
  const dispatch = useDispatch();
  const article = useSelector((state: RootState) => state.blogReducer.article);
  const isLoadingArticle = useSelector((state: RootState) => state.blogReducer.isLoadingArticle);
  const useMountEffect = (fun: any) => useEffect(fun, [])

  const fetchArticle = async () => {
    await dispatch(getArticle(slug))
  }

  useMountEffect(() => {
    if (slug) {
      fetchArticle()
    }else{
      <Redirect to='/404' />
    }
  })

  useEffect(() => {
    if(article && article.id){
      document.title = article.page_title ?? `Article : ${article.title} | Domiciliation et création d'entreprise en Tunisie`
      const description = document.querySelector('meta[name="description"]');
      if(description){
        description.setAttribute("content", article.page_description ?? article.preview);
      }
      const canonical = document.querySelector('link[rel="canonical"]');
      if(canonical){
        canonical.setAttribute("href", article.cononical_link ?? `https://domicilier.tn/blog/article/${article.id}`);
      }
    }
  }, [article])
  return (
    <div className="article-page">
      <Skeleton paragraph={{ rows: 8 }} loading={isLoadingArticle} active className={isLoadingArticle ? "max-height" : ""}>
        {article ?
          article.id ? (
            <>
              <ArticleFirstBlock />
              <div className={"head-and-faq-block"}>
                <ArticleBlock />
                <img src={orangeEllipse} alt="ellipse" />
              </div>
            </>
          ) : (
            null
          ) : (
            <Redirect to='/404' />
          )}
      </Skeleton>
      <StartDomiciliationBlock />
      <LandingFooter />
    </div>
  );
};

export default ArticlePage;
