import React, { useEffect, useState } from "react";
import { Carousel, Spin } from "antd";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import AddressCard from "../addresses-page-components/AddressCard";
import { LoadingOutlined } from '@ant-design/icons';

const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const AddressesCarousel = () => {
  const addresses = useSelector((state: RootState) => state.serivcesReducer.addresses);
  const [myAddresses, setMyAddresses] = useState<Array<{ id: number }>>([]);
  const isLoadingAddresses = useSelector((state: RootState) => state.serivcesReducer.isLoadingAddresses);
  const media = window.matchMedia("(max-width: 992px)");
  const chunk = (arr: any, size: number) => arr.reduce((acc: any, e: any, i: number) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);

  useEffect(() => {
    if (addresses.length > 0) {
      setMyAddresses(chunk(addresses.filter((address: any, i: number) => address.show_in_home), 3))
    }
  }, [addresses])
  return (
    <>
      <div className={"addresses-carousel-block"}>
        <div className={"max-width-container"}>
          {isLoadingAddresses ? (
            <div className="spinner-container" style={{ marginTop: "5%" }}>
              <Spin indicator={LoadingOutlinedIcon} size="large" />
            </div>
          ) : myAddresses.length > 0 ? (
            <>
              <h2>Choisissez votre case de départ
                des adresses de domiciliation en tunisie,
                <strong> Prestigieuse</strong><span> rien que pour vous !</span>
              </h2>
              <Carousel autoplay dots={true} arrows={false}>
                {media.matches ?
                  addresses.filter((addr :any, i: number) => addr.show_in_home).map((address: any, key: number) => (
                      <AddressCard
                        key={key}
                        addressData={address}
                      />
                  ))
                  : myAddresses.map((list: any, i: number) => (
                    <div key={i}>
                      <div className="addresses-wrapper">
                        {list.map((address: any, key: number) =>
                          <AddressCard
                            key={key}
                            addressData={address}
                          />
                        )}
                      </div>
                    </div>
                  ))
                }
              </Carousel>
            </>
          ) : null}
        </div >
      </div >
    </>
  );
};

export default AddressesCarousel;
