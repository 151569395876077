import React from "react";

const Place = () => {
  return (
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 2.24976C9.14627 2.24976 5.62502 5.77101 5.62502 10.1248C5.62502 16.031 13.5 24.7498 13.5 24.7498C13.5 24.7498 21.375 16.031 21.375 10.1248C21.375 5.77101 17.8538 2.24976 13.5 2.24976ZM13.5 12.9373C11.9475 12.9373 10.6875 11.6773 10.6875 10.1248C10.6875 8.57226 11.9475 7.31226 13.5 7.31226C15.0525 7.31226 16.3125 8.57226 16.3125 10.1248C16.3125 11.6773 15.0525 12.9373 13.5 12.9373Z" fill="white"/>
</svg>
  );
};

export default Place;
