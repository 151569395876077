import React from "react";
import { Row, Col } from 'antd';
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import businessmanWalking from "../../assets/png/businessman_walking.png";


const StartDomiciliationBlock = () => {
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.isLoggedIn);

  return (
    <>
      <div className={"start-domiciliation-block"}>
        <div className={"max-width-container"}>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} justify={"space-between"} align={"middle"}>
            <Col xs={24} md={12} lg={12}>
              <div>
                <div className={"title"}>
                  C’est aussi simple que ça ! <br />
                  Votre adresse <br /> de <span>domiciliation</span> vous attend!
                </div>
                {isLoggedIn ? null : (
                  <div className={"start-now"}>
                    <a href={process.env.REACT_APP_APP_URL + "/signup"}>Commencer ma domiciliation</a>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className={"svg-container-start-domiciliation"}>
                <img src={businessmanWalking} alt="img businessman walking" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default StartDomiciliationBlock;
