import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { Link } from "react-router-dom";
import AddressCard from "./AddressCard";
import { getAddresses } from "../../actions/services-actions/actions";
import { LoadingOutlined } from '@ant-design/icons';

const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const AddressesList = ({filter} : any) => {
  const dispatch = useDispatch();
  const addresses = useSelector((state: RootState) => state.serivcesReducer.addresses);
  const [myAddresses, setMyAddresses] = useState<Array<{ id: number }>>([]);
  const addresseDiv = document.getElementById('addresses-list');
  const isLoadingAddresses = useSelector((state: RootState) => state.serivcesReducer.isLoadingAddresses);
  const fetchAddresses = async () => {
    await dispatch(getAddresses())
  }
  useEffect(() => {
    if(addresses.length > 0){
      fetchAddresses()
    }
  }, [])
  useEffect(() => {
    if(addresses && addresses.length > 0){
      if (filter) {
        setMyAddresses(addresses.filter((address : any) => address.state == filter))
      }else{
        setMyAddresses(addresses)
      }
    }
  }, [addresses, filter])
  useEffect(() => {
    if (addresseDiv && filter) {
      addresseDiv.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }, [addresseDiv])
  return (
    <div className={"addresses-list-block max-width-container"} id={"addresses-list"}>
      <p>Trouvez votre adresse stratégique</p>
      {filter ? (
        <Breadcrumb className={"addresses-breadcrumb"} separator={<span>{">"}</span>}>
          <Breadcrumb.Item>
            <Link to="/addresses">Toutes nos adresses</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/addresses" className={"active-item"}>{filter}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      ) : null}

      {isLoadingAddresses ? (
        <div className="spinner-container" style={{ marginTop: "5%" }}>
          <Spin indicator={LoadingOutlinedIcon} size="large" />
        </div>
      ) : myAddresses.length > 0 ? (
        <Row className="addresses-list-wrapper" gutter={[32, 32]} >
          {myAddresses.map((address: any, key: number) => (
            <Col md={12} lg={8} xs={24} key={key}>
              <AddressCard
                key={key}
                addressData={address}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <em style={{ color: "#bfbfbf", textAlign: "center", display: "block", padding: "5% 0" }}>Aucune adresse</em>
      )}
    </div>
  );
};

export default AddressesList;
