import React, { useEffect, useState } from "react";
import { Button, notification } from 'antd';
import "./cookies-modal.scss";
import useCookies from "./useCookies";
import UniversalCookies from 'universal-cookie';
import cookie from "../../assets/png/cookies.png";
import { useHistory, useLocation } from "react-router-dom";

const cookies = new UniversalCookies();

const Cookies = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const { trackData } = useCookies();
  const location = useLocation();
  const history = useHistory();
  const useMountEffect = (fun: any) => useEffect(fun, []);
  const media = window.matchMedia("(max-width: 992px)");
  const openNotification = () => {
    notification.open({
      key: "cookies",
      duration: 0,
      message: <p>Salut c'est nous... <span>les Cookies !</span></p>,
      description:
        <div className="cookies-notif-body">
          <p>On a attendu d'être sûrs que le contenu de ce site vous intéresse avant de vous déranger, mais on aimerait bien vous accompagner pendant votre visite... C'est OK pour vous ?</p>
          <div className="btns">
            <Button className="refuse-btn" onClick={setCookieOff}>Non Merci</Button>
            <Button className="accept-btn" onClick={setCookieOn}>Ok pour moi</Button>
          </div>
        </div>,
      placement: media.matches ? "topLeft" : "bottomLeft",
      style: {
        backgroundImage: `url(${cookie})`
      },
      className: "cookies-notif"
    });
  };
  const setCookieOn = () => {
    let expDays = 365;
    let d = new Date();
    d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));
    cookies.set('Domicilier', 'on', { path: '/', secure: true, domain: window.location.hostname, expires: d, sameSite: 'strict' });
    notification.close("cookies");
    setIsEnabled(true);
  }
  const setCookieOff = () => {
    let expDays = 365;
    let d = new Date();
    d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));
    cookies.set('Domicilier', 'off', { path: '/', secure: true, domain: window.location.hostname, expires: d, sameSite: 'strict' });
    notification.close("cookies");
  }
  useMountEffect(() => {
    if (!cookies.get('Domicilier')) {
      setTimeout(() => {
        openNotification()
      }, 3000);
    } else {
      setIsEnabled(true);
    }
  })
  useEffect(() => {
    if (isEnabled) {
      const currentPath = location.pathname + location.search;
      trackData(currentPath);
    }
  }, [isEnabled]);
  useEffect(() => {
    return history.listen((loc: any) => { 
      const currentPath = loc.pathname + loc.search;
      trackData(currentPath);
    }) 
 }, [history, isEnabled]) 
  return null;
}
export default Cookies;
