import React from "react";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { Avatar, Row, Col } from 'antd';
import {  UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/fr'
import lightEllipse from "../../assets/png/Light-Ellipse.png";
moment.locale('fr')

const ArticleFirstBlock = () => {
  const article = useSelector((state: RootState) => state.blogReducer.article);
  const getImages = () => {
    if(article && article.pictures && article.pictures.length > 0){
      return article.pictures.map((pic: any, i: number) => (
        <img src={pic.url} alt="img" className={"img-" + i}/>
      ));
    }else{
      return null
    }
  }
  return (
    <>
      <div className={"article-first-block"} >
        <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
        <div className={"max-width-container"}>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} justify={"space-between"} align={"middle"}>
          <Col xs={24} md={10} lg={10}>
          <div className={"content"}>
            <h1>{article && article.title ? article.title : null}</h1>
            <div className={"article-author"}>
              <Avatar
                icon={<UserOutlined />}
                src={article && article.author.avatar ? process.env.REACT_APP_STORAGE_URL + article.author.avatar : null}
                size={30}
              />
              <p className={"author-name"}>{article && article.author.fname ? window.capitalize(article.author.fname) + ' ' + window.capitalize(article.author.lname) : null}</p>
              <div>{article && article.created_at ? moment(article.created_at).format("DD MMMM YYYY") : null }</div>
            </div>
          </div>
          </Col>
          <Col xs={24} md={14} lg={14}>
            <div className={"svg-container"}>
              {getImages()}
            </div>
          </Col>
        </Row>
        </div>
      </div>
    </>
  );
};

export default ArticleFirstBlock;
