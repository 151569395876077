import React from "react";
import TimerNew from "../../svg-components/icons/TimerNew";
import Focus from "../../svg-components/icons/Focus";
import Dash from "../../svg-components/icons/Dash";
import { Row, Col } from 'antd';


const AboutThirdBlock = () => {
  return (
    <>
      <div className={"about-third-block"}>
        <div className={"max-width-container"}>
          <h2><span>Un service de domiciliation unique et inédit en Tunisie et en Afrique</span>
            Le 1er e-commerce de domiciliation entiérement en ligne
          </h2>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <div className={"item"}>
                <div className={"header"}>
                  <Row gutter={[24, 24]}>
                    <Col xs={6} md={4}><TimerNew /></Col>
                    <Col xs={16} md={20}><h3>Gain de temps et 100% en ligne</h3></Col>
                  </Row>
                </div>
                <p>
                  Domiciliez votre entreprise  en
                  3 étapes clés et en 5 minutes
                </p>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className={"item"}>
                <div className={"header"}>
                  <Row gutter={[24, 24]}>
                    <Col xs={6} md={4}><Focus /></Col>
                    <Col xs={16} md={20}><h3>Des services <br /> sur-mesure</h3></Col>
                  </Row>
                </div>
                <p>
                  Une multitude de services sur-mesure pour vous accompagner  dans votre domiciliation
                </p>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className={"item"}>
                <div className={"header"}>
                  <Row gutter={[24, 24]}>
                    <Col xs={6} md={4}><Dash /></Col>
                    <Col xs={16} md={20}><h3>Gestion en temps réel</h3></Col>
                  </Row>
                </div>
                <p>
                  Bénéficiez d’une interface de gestion de courrier en temps réel
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AboutThirdBlock;
