import React, { useEffect } from "react";
import "./blog-page.scss";
import LandingFooter from "../../components/landing-footer/LandingFooter"
import BlogFirstBlock from "./BlogFirstBlock";
import StartDomiciliationBlock from "../../components/addresses-page-components/StartDomiciliationBlock";
import ArticlesBlock from "./ArticlesBlock";
import orangeEllipse from "../../assets/png/Orange-Ellipse.png";

const BlogPage = () => {
  useEffect(() => {
      document.title = `Blog | Domiciliation et création d'entreprise en Tunisie`
      const description = document.querySelector('meta[name="description"]');
      if(description){
        description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
      }
      const canonical = document.querySelector('link[rel="canonical"]');
      if(canonical){
        canonical.setAttribute("href", `https://domicilier.tn/blog`);
      }
  })
  return (
      <div className="blog-page">
        <BlogFirstBlock/>
        <div className={"head-and-faq-block"}>
          <ArticlesBlock/>
          <img src={orangeEllipse} alt="ellipse" />
        </div>
        <StartDomiciliationBlock/>
        <LandingFooter/>
      </div>
  );
};

export default BlogPage;
