import React from "react";
import { Row, Col } from 'antd';

const DomiciliationSecondBlock = () => {
  return (
    <>
    <div className={"domiciliation-second-block"} >
      <div className={"max-width-container"}>
        <h2>Une adresse, <strong>des privilèges !</strong></h2>
        <Row className={"assets-wrapper"} justify={"space-between"} >
          <Col xs={24} md={8}>
            <div className={"asset-item"}>
              <h3>Protéger votre vie privée, valoriser votre image de marque </h3>
              <p>Une adresse exclusivement professionnelle, pour préserver votre vie privée. Une large palette d’adresses prestigieuses à Tunis et partout en Tunisie sera à votre disposition.</p>
            </div>
          </Col>
          <Col xs={24} md={8}>
          <div className={"asset-item"}>
            <h3>Faites des économies <br /> sur différents <br /> frais</h3>
            <p>Economisez les frais de transfert de siège social qui peuvent s’élever jusqu’à 700 ou 800DT.</p>
          </div>
          </Col>
          <Col xs={24} md={8}>
          <div className={"asset-item"}>
            <h3>Plus de liberté et des services pour soulager certains aspects administratifs.</h3>
            <p>Recevez votre courrier postal à l’adresse de votre choix ou numérisé sur votre espace client.</p>
          </div>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};

export default DomiciliationSecondBlock;
