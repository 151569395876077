import React, { useEffect, useRef } from "react";
import $ from "jquery";

const PlusSmallIcon = ({rotate}: any) => {
  const icon = useRef(null);
  useEffect(() => {
    const currentIcon: any = icon.current;
    if(rotate == 90){
        $(currentIcon).css("transform", "rotate(45deg)");
      
    }else{
      $(currentIcon).css("transform", "rotate(90deg)");
    }
  }, [rotate])

  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" ref={icon}>
      <path d="M30.9587 17.7077H17.7087V30.9577H13.292V17.7077H0.0419922V13.291H13.292V0.0410156H17.7087V13.291H30.9587V17.7077Z" fill="#2A3F58"/>
    </svg>
  );
};

export default PlusSmallIcon;
