import React, { useEffect } from "react";
import "./job-offer-page.scss";
import { Skeleton } from 'antd';
import LandingFooter from "../../components/landing-footer/LandingFooter"
import { getJobOffer } from "../../actions/services-actions/actions";
import JobOfferFirstBlock from "./JobOfferFirstBlock";
import JobOfferBlock from "./JobOfferBlock";
import { RootState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";

import {
  useParams,
  Redirect
} from "react-router-dom";

const JobOfferPage = (history: any) => {
  let { slug }: any = useParams();
  const dispatch = useDispatch();
  const jobOffer = useSelector((state: RootState) => state.serivcesReducer.jobOffer);
  const isLoadingJob = useSelector((state: RootState) => state.serivcesReducer.isLoadingJob);
  const useMountEffect = (fun: any) => useEffect(fun, [])

  const fetchJobOffer = async () => {
    await dispatch(getJobOffer(slug))
  }
  useMountEffect(() => {
    if (slug) {
      fetchJobOffer()
    }else{
      <Redirect to='/404' />
    }
  })
  useEffect(() => {
    if(jobOffer && jobOffer.title){
      document.title = `Recrutement ${jobOffer.title} | Domiciliation et création d'entreprise en Tunisie`
      const description = document.querySelector('meta[name="description"]');
      if(description){
        description.setAttribute("content", `${jobOffer.preview}`);
      }
      const canonical = document.querySelector('link[rel="canonical"]');
      if(canonical){
        canonical.setAttribute("href", `https://domicilier.tn/career/job-offer/${jobOffer.id}`);
      }
    }
  }, [jobOffer])
  return (
    <div className="job-offer-page">
      <Skeleton paragraph={{ rows: 8 }} loading={isLoadingJob} active className={isLoadingJob ? "max-height" : ""}>
        {jobOffer ?
          jobOffer.id ? (
            <>
              <JobOfferFirstBlock />
              <div className={"head-and-faq-block"}>
                <JobOfferBlock />
              </div>
            </>
          ) : (
            null
          ) : (
            <Redirect to='/404' />
          )}
      </Skeleton>
      <LandingFooter />
    </div>
  );
};

export default JobOfferPage;
