import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col } from 'antd';
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import BenefitsOnlineSmallIcon from "../../svg-components/icons/BenefitsOnlineSmallIcon";
import BenefitsQRSmallIcon from "../../svg-components/icons/BenefitsQRSmallIcon";
import BenefitsAppSmallIcon from "../../svg-components/icons/BenefitsAppSmallIcon";
import BenefitsSecurePaySmallIcon from "../../svg-components/icons/BenefitsSecurePaySmallIcon";
import BenefitsSupportSmallIcon from "../../svg-components/icons/BenefitsSupportSmallIcon";
import BenefitsAuthSmallIcon from "../../svg-components/icons/BenefitsAuthSmallIcon";
import domi from "../../assets/png/annie-spratt.png";
import ApplyModel from "../../components/job-offers-components/ApplyModel";
import JobAppliedModel from "../../components/job-offers-components/JobAppliedModel";

const JoinBlock = () => {
  const applied = useSelector((state: RootState) => state.serivcesReducer.applied);
  const [applicationModal, setApplicationModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  useEffect(() => {
    if(applied){
        setApplicationModal(false);
        setSuccessModal(true);
    }
}, [applied])
  return (
    <>
      <div className={"join-us-block"} >
        <div className={"max-width-container"}>
          <Row className={"content"} justify={"space-between"} >
            <Col xs={24} md={14} className={"left"}>
              <p className={"mute"}>Collaborez avec nous</p>
              <h2>L’équipe Domicilier.tn</h2>
              <p>Domicilier.tn est la première plateforme permettant aux grandes entreprises d'acheter les produits des professionnels les plus talentueux du monde.
                Nous changeons la façon dont le monde fonctionne. <strong>Et vous pourriez en faire partie.</strong></p>
              <Link to={"/apropos-domiciliation-tunisie"} className={"blue-btn"}>En savoir plus</Link>
            </Col>
            <Col xs={24} md={10}>
              <img src={domi} alt="img" />
            </Col>
          </Row>
        </div>
        <div className={"join-us-process"}>
          <div className={"max-width-container"}>
            <Row gutter={[{
              xs: 8, sm:
                16, md: 24, lg: 32
            }, 16]} justify={"space-between"}>
              <Col xs={24} lg={10}>
                <div >
                  <h2 className={"title"}>Rejoignez un lieu de travail qui vous en donne plus</h2>
                  <p>
                  Opportunités d'apprentissage, budgets de réseautage, horaires flexibles, principaux avantages pour garder les employés en bonne santé et heureux.
                    </p>
                  <Button onClick={() => setApplicationModal(true)} className="blue-btn">Postuler</Button>
                </div>
              </Col>
              <Col xs={24} lg={14}>
                <Row gutter={[24, 24]} style={{ marginRight: "10%", marginLeft: "10%" }} className={"service-benefits-list"}>
                  <Col md={8} xs={24}>
                    <div className={"service-landing-item"}>
                      <div className="svg-service-container">
                        {
                          <BenefitsOnlineSmallIcon />}
                      </div>
                      <h3>Flexibilité</h3>
                    </div>
                  </Col>
                  <Col md={8} xs={24}>
                    <div className={"service-landing-item"}>
                      <div className="svg-service-container">{
                        <BenefitsQRSmallIcon />}</div>
                      <h3>Signature electronique</h3>
                    </div>
                  </Col>
                  <Col md={8} xs={24}>
                    <div className={"service-landing-item"}>
                      <div className="svg-service-container">{
                        <BenefitsAppSmallIcon />}</div>
                      <h3>Primes annuelles</h3>
                    </div>
                  </Col>
                  <Col md={8} xs={24}>
                    <div className={"service-landing-item"}>
                      <div className="svg-service-container">{
                        <BenefitsSecurePaySmallIcon />}</div>
                      <h3>Paiement sécruisé</h3>
                    </div>
                  </Col>
                  <Col md={8} xs={24}>
                    <div className={"service-landing-item"}>
                      <div className="svg-service-container">{
                        <BenefitsSupportSmallIcon />}</div>
                      <h3>Support <br /> 7/7</h3>
                    </div>
                  </Col>
                  <Col md={8} xs={24}>
                    <div className={"service-landing-item"}>
                      <div className="svg-service-container">{
                        <BenefitsAuthSmallIcon />}</div>
                      <h3>Double authentification</h3>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <ApplyModel
            visible={applicationModal}
            abort={() => setApplicationModal(false)}
          />
          <JobAppliedModel
            visible={successModal}
            abort={() => setSuccessModal(false)}
          />
        </div>
      </div>
    </>
  );
};

export default JoinBlock;
