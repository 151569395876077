import React from "react";

const KEY = process.env.REACT_APP_HUBSPOT_ID;

const Hubspot = () => {
    React.useEffect(() => {
        // if (process.env.NODE_ENV === "production") {
            // Create script component.
            let script = document.createElement('script');
            script.type = "application/javascript";
            script.src = `//js-eu1.hs-scripts.com/${KEY}.js`;
            script.async = true;

            setTimeout(() => {
                document.body.appendChild(script);
            }, 3000);

            return () => {
                document.body.removeChild(script);
            }
        // }

    }, []);
    return null
}
export default Hubspot;
