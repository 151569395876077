import React, { useEffect, useState } from "react";
import { Input, Select } from "antd";

const { Option } = Select;

interface FAQSearchBarProps {
  search: any,
}

const FAQSearchBar: React.FC<FAQSearchBarProps> = ({ search }) => {
  const [filter, setFilter] = useState('key_word');
  const [key, setKey] : [any, (key: any) => void] = useState<any>(null);
  const options = [
    <Option key={1} value={"key_word"}>Mots-clés</Option>,
  ];
  const changeFilter = (value: any) => {
    setFilter(value);
    setKey(null);
  }
  useEffect(() => {
    if (key && key.length > 0) {
      const values = { key: key, filter: filter }
      search(values)
    } else {
      setKey(null)
      search(null)
    }
  }, [key || filter])
  return (
    <div className={"search-bar"}>
      <Input
        placeholder="Rechercher..."
        onChange={(e) => setKey(e.target.value.trim())}
        value={key}
        addonAfter={
          <Select
            style={{ width: '100%' }}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onChange={changeFilter}
            value={filter}
          >
            {options}
          </Select>}
      />
    </div>
  );
};

export default FAQSearchBar;
