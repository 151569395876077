import React from "react";
import { Table } from 'antd';


const CreateEntrepriseSecondBlock = () => {
  const dataSource = [
    {
      key: '1',
      name: 'Adresses administratives',
      basic: '*',
      prestige: '*',
      premium: '*',
    },
    {
      key: '2',
      name: 'Domiciliation juridique, fiscale et commerciale de votre siège social',
      basic: '*',
      prestige: '*',
      premium: '*',
    },
    {
      key: '3',
      name: 'Notification par mail ou sms lors de la réception du courrier',
      basic: '*',
      prestige: '*',
      premium: '*',
    },
    {
      key: '4',
      name: 'Réexpédition de votre courrier vers l’adresse de votre choix',
      basic: '*',
      prestige: '*',
      premium: '*',
    },
    {
      key: '5',
      name: 'Logo professionnel',
      basic: '*',
      prestige: '*',
      premium: '*',
    },
    {
      key: '6',
      name: 'Carte visite',
      basic: '100',
      prestige: '200',
      premium: '350',
    },
    {
      key: '7',
      name: 'Cachet d’entreprise',
      basic: '*',
      prestige: '*',
      premium: '*',
    },
    {
      key: '8',
      name: 'Réception de vos appels téléphoniques et notifications',
      basic: 'X',
      prestige: '*',
      premium: '*',
    },
    {
      key: '9',
      name: 'Mise à disposition d’un espace coworking',
      basic: 'X',
      prestige: '3H',
      premium: '5H',
    },
    {
      key: '10',
      name: 'Scan et envoi de votre courrier par mail (avec autorisation)',
      basic: 'X',
      prestige: 'X',
      premium: 'X',
    },
  ];

  const columns = [
    {
      title: <h3>Domiciliation</h3>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Pack basic',
      dataIndex: 'basic',
      key: 'basic',
    },
    {
      title: 'Pack Prestige',
      dataIndex: 'prestige',
      key: 'prestige',
    },
    {
      title: 'Pack Premium',
      dataIndex: 'premium',
      key: 'premium',
    },
  ];
  return (
    <>
    <div className={"create-entreprise-second-block"} >
      <div className={"max-width-container"}>
        <div className={"header"}>
          <h2>Grille tarifaire simple, <br /> <strong>sans frais cachés</strong></h2>
          <p>Domicilier.tn adopte une grille tarifaire transparente et sans surprise ! Chacun sait ce qu’il paie, et ne paie que ce dont il a besoin !</p>
        </div>
        <Table dataSource={dataSource} columns={columns} className={"tarification-table"} pagination={false}/>
      </div>
    </div>
    </>
  );
};

export default CreateEntrepriseSecondBlock;
