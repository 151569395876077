import React from "react";
import { Row, Col } from 'antd';
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import firstImg from "../../assets/png/addresses_page_first.png";
import secondImg from "../../assets/png/addresses_page_second.png";
import lightEllipse from "../../assets/png/Light-Ellipse.png";


const AddressesFirstBlock = () => {
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.isLoggedIn);

  return (
    <>
      <div className={"addresses-first-block"}>
        <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
        <div className={"max-width-container"}>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} justify={"space-between"} align={"middle"}>
            <Col xs={24} md={10} lg={10}>
              <div>
                <div>
                  <h1 className={"huge-blue-txt-h1"}>Profitez de nos adresses de domiciliation en Tunisie grâce domicilier.tn</h1>
                </div>
                <p>
                Domiciliez.tn met à votre disposition une large gamme de services de domiciliation en Tunisie et vous facilite la tâche de domiciliation grâce à un système d’achat intelligent et entièrement en ligne.
                </p>
                {isLoggedIn ? null : (
                  <div className={"start-now"}>
                    <a href={process.env.REACT_APP_APP_URL + "/signup"}>Commencer ma domiciliation</a>
                  </div>

                )}
              </div>
            </Col>
            <Col xs={24} md={14} lg={14}>
              <div className={"svg-container-addresses"}>
                <img src={secondImg} alt="img" className={"img-1"} />
                <img src={firstImg} alt="img" className={"img-2"} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddressesFirstBlock;
