import React, { useEffect, useState } from "react";
import "./contact-me-page.scss";
import { Form, Input, Spin, message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axiosInstance from "../../config/axios-instance";
import ContactMeModal from "./ContactMeModal";

import Saly from "../../assets/png/Saly-14.png";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#3240FF" }} spin />;

const ContactMePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [VisibleModal, setVisibleModal] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    if (values) {
      setIsLoading(true);
      axiosInstance({
        method: "post",
        url: "services/contact-me",
        data: values,
      })
        .then((response) => {
          setIsLoading(false);
          setVisibleModal(true);
          form.resetFields();
        })
        .catch((e) => {
          setIsLoading(false);
          message.error({
            content: 'Un probème est survenu! Veuillez réessayer plus tard.',
            className: 'error-message',
            duration: 3
          });
        })
    }
  }
  useEffect(() => {
    document.title = `Je souhaite être contacté | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", "https://domicilier.tn/contact-me");
    }
  })
  return (
    <div className={"contact-me-page"} style={{ backgroundImage: `url('${Saly}')` }}>
      <div className="max-width-container">
        <div className={"content"}>
          <div className={"header"}>
            <p>Je souhaite être contacté</p>
            <h1 className={"title"}>
              <strong>Domicilier.tn</strong> <br />
              vous remercie pour votre compréhension et votre confiance
            </h1>
          </div>
          <Form
            name="contact-me-form"
            onFinish={onFinish}
            className={"contact-me-form"}
            form={form}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Tapez votre nom et prénom SVP!",
                },
              ]}
            >
              <Input placeholder={"Nom et Prénom"} />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Tapez votre email SVP!",
                },
              ]}
            >
              <Input placeholder={"Email"} />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Tapez votre numéro de téléphone SVP!",
                },
              ]}
            >
              <Input placeholder={"Téléphone"} />
            </Form.Item>
            <button className={"contact-me-submit-btn"} type="submit">
              {isLoading ? (<Spin indicator={antIcon} />) : "Envoyer"}
            </button>
          </Form>
        </div>
        <div className={"mobile-image-container"}>
          <img src={Saly} alt="img" />
        </div>
      </div>
      <ContactMeModal
        visible={VisibleModal}
        abort={() => setVisibleModal(false)}
      />
    </div>
  );
};

export default ContactMePage;
