import React from "react";

const ServicesBuildingSmallIcon = () => {
  return (
    <svg viewBox="0 0 61 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M59.6177 63.7753H57.7612V5.56179C57.7612 4.94729 57.2624 4.44946 56.6473 4.44946H35.8544C35.2391 4.44946 34.7405 4.94729 34.7405 5.56179V10.382H32.5127V5.56179C32.5127 4.94729 32.0139 4.44946 31.3988 4.44946H26.5718V1.11233C26.5718 0.497836 26.073 0 25.4579 0C24.8428 0 24.3441 0.497836 24.3441 1.11233V4.44946H19.5172C18.9019 4.44946 18.4033 4.94729 18.4033 5.56179V10.382H15.0615C14.4463 10.382 13.9476 10.8798 13.9476 11.4943V20.764H4.66509C4.04987 20.764 3.55121 21.2618 3.55121 21.8763V63.7752H1.69445C1.07922 63.7752 0.580566 64.273 0.580566 64.8875C0.580566 65.502 1.07935 66 1.69445 66H59.6177C60.2329 66 60.7316 65.5022 60.7316 64.8877C60.7316 64.2732 60.2328 63.7753 59.6177 63.7753ZM20.6309 6.67412H30.2847V10.382H20.6309V6.67412ZM16.9179 63.7753H16.1753V61.9214C16.1753 61.3069 15.6765 60.8091 15.0614 60.8091C14.4463 60.8091 13.9475 61.3069 13.9475 61.9214V63.7753H13.2049V60.0675H16.9179V63.7753ZM24.3439 35.2247V37.1383V63.7753H19.1456V58.9551C19.1456 58.3406 18.6469 57.8428 18.0318 57.8428H12.091C11.4758 57.8428 10.9771 58.3406 10.9771 58.9551V63.7753H5.77884V22.9888H24.3439V35.2247ZM25.4578 20.7641H16.1753V12.6068H34.7403V34.1123H26.5718V30.4045H29.9136C30.5288 30.4045 31.0275 29.9066 31.0275 29.2921C31.0275 28.6776 30.5287 28.1798 29.9136 28.1798H26.5718V24.4719H29.9136C30.5288 24.4719 31.0275 23.9741 31.0275 23.3596C31.0275 22.7451 30.5287 22.2473 29.9136 22.2473H26.5718V21.8766C26.5718 21.2619 26.073 20.7641 25.4578 20.7641ZM45.1369 63.7753H26.5718V37.1383V36.3371H45.1369V63.7753ZM55.5333 63.7753H47.3647V35.2247V33.7415C47.3647 33.127 46.8659 32.6291 46.2508 32.6291C45.6357 32.6291 45.1369 33.127 45.1369 33.7415V34.1122H42.9091V33.7415C42.9091 33.127 42.4104 32.6291 41.7953 32.6291C41.1802 32.6291 40.6814 33.127 40.6814 33.7415V34.1122H36.9684V11.4944V6.67412H55.5334V63.7753H55.5333Z"
        fill="white"
      />
      <path
        d="M31.3985 61.5508C32.0138 61.5508 32.5124 61.0527 32.5124 60.4385V39.6745C32.5124 39.06 32.0136 38.5622 31.3985 38.5622C30.7835 38.5622 30.2847 39.06 30.2847 39.6745V60.4386C30.2847 61.0528 30.7833 61.5508 31.3985 61.5508Z"
        fill="white"
      />
      <path
        d="M35.8541 61.5508C36.4693 61.5508 36.968 61.0527 36.968 60.4385V39.6745C36.968 39.06 36.4692 38.5622 35.8541 38.5622C35.239 38.5622 34.7402 39.06 34.7402 39.6745V60.4386C34.7401 61.0528 35.2389 61.5508 35.8541 61.5508Z"
        fill="white"
      />
      <path
        d="M40.3099 61.5508C40.9251 61.5508 41.4238 61.0527 41.4238 60.4385V39.6745C41.4238 39.06 40.925 38.5622 40.3099 38.5622C39.6948 38.5622 39.196 39.06 39.196 39.6745V60.4386C39.196 61.0528 39.6948 61.5508 40.3099 61.5508Z"
        fill="white"
      />
      <path
        d="M41.795 14.0898C42.4103 14.0898 42.9089 13.592 42.9089 12.9775V11.4944C42.9089 10.8799 42.4101 10.3821 41.795 10.3821C41.1799 10.3821 40.6812 10.8799 40.6812 11.4944V12.9776C40.6812 13.592 41.1798 14.0898 41.795 14.0898Z"
        fill="white"
      />
      <path
        d="M46.2506 14.0898C46.8658 14.0898 47.3645 13.592 47.3645 12.9775V11.4944C47.3645 10.8799 46.8657 10.3821 46.2506 10.3821C45.6355 10.3821 45.1367 10.8799 45.1367 11.4944V12.9776C45.1367 13.592 45.6354 14.0898 46.2506 14.0898Z"
        fill="white"
      />
      <path
        d="M50.7064 14.0898C51.3216 14.0898 51.8203 13.592 51.8203 12.9775V11.4944C51.8203 10.8799 51.3215 10.3821 50.7064 10.3821C50.0913 10.3821 49.5925 10.8799 49.5925 11.4944V12.9776C49.5925 13.592 50.0912 14.0898 50.7064 14.0898Z"
        fill="white"
      />
      <path
        d="M41.795 21.5056C42.4103 21.5056 42.9089 21.0077 42.9089 20.3932V18.91C42.9089 18.2955 42.4101 17.7977 41.795 17.7977C41.1799 17.7977 40.6812 18.2955 40.6812 18.91V20.3932C40.6812 21.0077 41.1798 21.5056 41.795 21.5056Z"
        fill="white"
      />
      <path
        d="M46.2506 21.5056C46.8658 21.5056 47.3645 21.0077 47.3645 20.3932V18.91C47.3645 18.2955 46.8657 17.7977 46.2506 17.7977C45.6355 17.7977 45.1367 18.2955 45.1367 18.91V20.3932C45.1367 21.0077 45.6354 21.5056 46.2506 21.5056Z"
        fill="white"
      />
      <path
        d="M50.7064 21.5056C51.3216 21.5056 51.8203 21.0077 51.8203 20.3932V18.91C51.8203 18.2955 51.3215 17.7977 50.7064 17.7977C50.0913 17.7977 49.5925 18.2955 49.5925 18.91V20.3932C49.5925 21.0077 50.0912 21.5056 50.7064 21.5056Z"
        fill="white"
      />
      <path
        d="M41.795 28.9214C42.4103 28.9214 42.9089 28.4236 42.9089 27.8091V26.3259C42.9089 25.7114 42.4101 25.2136 41.795 25.2136C41.1799 25.2136 40.6812 25.7114 40.6812 26.3259V27.8091C40.6812 28.4235 41.1798 28.9214 41.795 28.9214Z"
        fill="white"
      />
      <path
        d="M46.2506 28.9214C46.8658 28.9214 47.3645 28.4236 47.3645 27.8091V26.3259C47.3645 25.7114 46.8657 25.2136 46.2506 25.2136C45.6355 25.2136 45.1367 25.7114 45.1367 26.3259V27.8091C45.1367 28.4235 45.6354 28.9214 46.2506 28.9214Z"
        fill="white"
      />
      <path
        d="M50.7064 28.9214C51.3216 28.9214 51.8203 28.4236 51.8203 27.8091V26.3259C51.8203 25.7114 51.3215 25.2136 50.7064 25.2136C50.0913 25.2136 49.5925 25.7114 49.5925 26.3259V27.8091C49.5925 28.4235 50.0912 28.9214 50.7064 28.9214Z"
        fill="white"
      />
      <path
        d="M10.6058 31.8877C11.221 31.8877 11.7197 31.3898 11.7197 30.7753V29.2921C11.7197 28.6776 11.2209 28.1798 10.6058 28.1798C9.99073 28.1798 9.49194 28.6776 9.49194 29.2921V30.7753C9.49194 31.3897 9.99073 31.8877 10.6058 31.8877Z"
        fill="white"
      />
      <path
        d="M15.0611 31.8877C15.6764 31.8877 16.175 31.3898 16.175 30.7753V29.2921C16.175 28.6776 15.6762 28.1798 15.0611 28.1798C14.4461 28.1798 13.9473 28.6776 13.9473 29.2921V30.7753C13.9473 31.3897 14.4459 31.8877 15.0611 31.8877Z"
        fill="white"
      />
      <path
        d="M19.517 31.8877C20.1322 31.8877 20.6308 31.3898 20.6308 30.7753V29.2921C20.6308 28.6776 20.132 28.1798 19.517 28.1798C18.9019 28.1798 18.4031 28.6776 18.4031 29.2921V30.7753C18.4031 31.3897 18.9017 31.8877 19.517 31.8877Z"
        fill="white"
      />
      <path
        d="M10.6058 39.3034C11.221 39.3034 11.7197 38.8055 11.7197 38.1911V36.7079C11.7197 36.0934 11.2209 35.5955 10.6058 35.5955C9.99073 35.5955 9.49194 36.0934 9.49194 36.7079V38.1911C9.49194 38.8054 9.99073 39.3034 10.6058 39.3034Z"
        fill="white"
      />
      <path
        d="M15.0611 39.3034C15.6764 39.3034 16.175 38.8055 16.175 38.1911V36.7079C16.175 36.0934 15.6762 35.5955 15.0611 35.5955C14.4461 35.5955 13.9473 36.0934 13.9473 36.7079V38.1911C13.9473 38.8054 14.4459 39.3034 15.0611 39.3034Z"
        fill="white"
      />
      <path
        d="M19.517 39.3034C20.1322 39.3034 20.6308 38.8055 20.6308 38.1911V36.7079C20.6308 36.0934 20.132 35.5955 19.517 35.5955C18.9019 35.5955 18.4031 36.0934 18.4031 36.7079V38.1911C18.4031 38.8054 18.9017 39.3034 19.517 39.3034Z"
        fill="white"
      />
      <path
        d="M10.6058 46.7191C11.221 46.7191 11.7197 46.2213 11.7197 45.6068V44.1236C11.7197 43.5091 11.2209 43.0112 10.6058 43.0112C9.99073 43.0112 9.49194 43.5091 9.49194 44.1236V45.6068C9.49194 46.2213 9.99073 46.7191 10.6058 46.7191Z"
        fill="white"
      />
      <path
        d="M15.0611 46.7191C15.6764 46.7191 16.175 46.2213 16.175 45.6068V44.1236C16.175 43.5091 15.6762 43.0112 15.0611 43.0112C14.4461 43.0112 13.9473 43.5091 13.9473 44.1236V45.6068C13.9473 46.2213 14.4459 46.7191 15.0611 46.7191Z"
        fill="white"
      />
      <path
        d="M19.517 46.7191C20.1322 46.7191 20.6308 46.2213 20.6308 45.6068V44.1236C20.6308 43.5091 20.132 43.0112 19.517 43.0112C18.9019 43.0112 18.4031 43.5091 18.4031 44.1236V45.6068C18.4031 46.2213 18.9017 46.7191 19.517 46.7191Z"
        fill="white"
      />
      <path
        d="M10.6058 54.1348C11.221 54.1348 11.7197 53.637 11.7197 53.0225V51.5393C11.7197 50.9248 11.2209 50.427 10.6058 50.427C9.99073 50.427 9.49194 50.9248 9.49194 51.5393V53.0225C9.49194 53.637 9.99073 54.1348 10.6058 54.1348Z"
        fill="white"
      />
      <path
        d="M15.0611 54.1348C15.6764 54.1348 16.175 53.637 16.175 53.0225V51.5393C16.175 50.9248 15.6762 50.427 15.0611 50.427C14.4461 50.427 13.9473 50.9248 13.9473 51.5393V53.0225C13.9473 53.637 14.4459 54.1348 15.0611 54.1348Z"
        fill="white"
      />
      <path
        d="M19.517 54.1348C20.1322 54.1348 20.6308 53.637 20.6308 53.0225V51.5393C20.6308 50.9248 20.132 50.427 19.517 50.427C18.9019 50.427 18.4031 50.9248 18.4031 51.5393V53.0225C18.4031 53.637 18.9017 54.1348 19.517 54.1348Z"
        fill="white"
      />
      <path
        d="M50.7064 36.3371C51.3216 36.3371 51.8203 35.8393 51.8203 35.2248V33.7416C51.8203 33.1271 51.3215 32.6293 50.7064 32.6293C50.0913 32.6293 49.5925 33.1271 49.5925 33.7416V35.2248C49.5925 35.8392 50.0912 36.3371 50.7064 36.3371Z"
        fill="white"
      />
      <path
        d="M50.7064 43.7528C51.3216 43.7528 51.8203 43.255 51.8203 42.6405V41.1573C51.8203 40.5428 51.3215 40.045 50.7064 40.045C50.0913 40.045 49.5925 40.5428 49.5925 41.1573V42.6405C49.5925 43.2549 50.0912 43.7528 50.7064 43.7528Z"
        fill="white"
      />
      <path
        d="M50.7064 51.1686C51.3216 51.1686 51.8203 50.6707 51.8203 50.0562V48.573C51.8203 47.9585 51.3215 47.4607 50.7064 47.4607C50.0913 47.4607 49.5925 47.9585 49.5925 48.573V50.0562C49.5925 50.6706 50.0912 51.1686 50.7064 51.1686Z"
        fill="white"
      />
      <path
        d="M50.7064 58.5843C51.3216 58.5843 51.8203 58.0864 51.8203 57.4719V55.9888C51.8203 55.3743 51.3215 54.8764 50.7064 54.8764C50.0913 54.8764 49.5925 55.3743 49.5925 55.9888V57.4719C49.5925 58.0863 50.0912 58.5843 50.7064 58.5843Z"
        fill="white"
      />
      <path
        d="M31.0275 17.427C31.0275 16.8125 30.5287 16.3146 29.9136 16.3146H21.0023C20.3871 16.3146 19.8884 16.8125 19.8884 17.427C19.8884 18.0415 20.3872 18.5393 21.0023 18.5393H29.9136C30.5288 18.5393 31.0275 18.0415 31.0275 17.427Z"
        fill="white"
      />
    </svg>
  );
};

export default ServicesBuildingSmallIcon;
