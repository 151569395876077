import React, { useEffect } from "react";
import "./create-entreprise-page.scss";
import LandingFooter from "../../../components/landing-footer/LandingFooter"
import FAQBlock from "./FAQBlock";
import GetYourHeadOffice from "../../../components/addresses-page-components/GetYourHeadOffice";
import CreateEntrepriseFirstBlock from "./CreateEntrepriseFirstBlock";
import CreateEntrepriseSecondBlock from "./CreateEntrepriseSecondBlock";
import CreateEntrepriseThirdBlock from "./CreateEntrepriseThirdBlock";
import LegalForms from "./LegalForms";
import orangeEllipse from "../../../assets/png/Orange-Ellipse.png";

const CreateEntreprisePage = () => {
  useEffect(() => {
    document.title = `Création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Création d'entreprise en Tunisie 100% en ligne.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://domicilier.tn/services/create-entreprise`);
    }
  })
  return (
      <div className="create-entreprise-page">
        <CreateEntrepriseFirstBlock/>
        <CreateEntrepriseSecondBlock/>
        <CreateEntrepriseThirdBlock/>
        <LegalForms/>
        <div className={"head-and-faq-block"}>
          <GetYourHeadOffice />
          <img src={orangeEllipse} alt="ellipse" />
          <FAQBlock/>
        </div>
        <LandingFooter/>
      </div>
  );
};

export default CreateEntreprisePage;
