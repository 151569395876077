import React from "react";
import { Row, Col } from 'antd';
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import PercentageSmallIconSvg from "../../../svg-components/icons/PercentageSmallIconSvg";
import ServicesBuildingSmallIcon from "../../../svg-components/icons/ServicesBuildingSmallIcon";


const CreateEntrepriseThirdBlock = () => {
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.isLoggedIn);

  return (
    <>
      <div className={"create-entreprise-third-block"} >
        <div className={"max-width-container"}>
          <h2>Un service de domiciliation unique et inédit <br /> <span>Création d’entreprise</span></h2>
          <Row gutter={[24, 24]} className={"assets-wrapper"} justify={"space-between"} >
            <Col xs={24} md={12}>
              <div className={"asset-item"}>
                <div className={"svg-container"}><PercentageSmallIconSvg /></div>
                <h3>Création Entreprise SARL/SUARL</h3>
                <p>A partir de 1800DT*</p>
                {isLoggedIn ? null : (
                  <a
                    href={process.env.REACT_APP_APP_URL + "/signup"}
                    className={"blue-btn"}
                  >
                    Créer maintenant
                  </a>
                )}
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className={"asset-item"}>
                <div className={"svg-container"}><ServicesBuildingSmallIcon /></div>
                <h3>Création Entreprise SA</h3>
                <p>A partir de 1800DT*</p>
                {isLoggedIn ? null : (
                  <a
                    href={process.env.REACT_APP_APP_URL + "/signup"}
                    className={"blue-btn"}
                  >
                    Créer maintenant
                  </a>
                )}
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className={"asset-item"}>
                <div className={"svg-container"}><PercentageSmallIconSvg /></div>
                <h3>Création Association</h3>
                <p>A partir de 1250DT*</p>
                {isLoggedIn ? null : (
                  <a
                    href={process.env.REACT_APP_APP_URL + "/signup"}
                    className={"blue-btn"}
                  >
                    Créer maintenant
                  </a>
                )}
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className={"asset-item"}>
                <div className={"svg-container"}><ServicesBuildingSmallIcon /></div>
                <h3>Création Société Offshore</h3>
                <p>A partir de 1800DT*</p>
                {isLoggedIn ? null : (
                  <a
                    href={process.env.REACT_APP_APP_URL + "/signup"}
                    className={"blue-btn"}
                  >
                    Créer maintenant
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CreateEntrepriseThirdBlock;
