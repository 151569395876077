import React, { useEffect, useState } from "react";
import {
  useHistory,
  Link,
} from "react-router-dom";
import { Drawer, Menu } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { CLOSE_ADDRESSES_SIDER } from "../../actions/ui-actions/types";
import SmallPromotionalCard from "../promotional-cards-components/SmallPromotionalCard";

const AddressesSidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const promotions = useSelector((state: RootState) => state.serivcesReducer.promotions);
  const addresses = useSelector((state: RootState) => state.serivcesReducer.addresses);
  const addresseSiderOpen = useSelector((state: RootState) => state.uiReducer.addresseSiderOpen);
  const [states, setStates] : any = useState(null);
  const [sidebarPromotions, setSidebarPromotions] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const screenWidth : any = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

  const onClose = () => {
    dispatch({ type: CLOSE_ADDRESSES_SIDER });
  }
  const getStates = () => {
    let array: Array<string> = [];
    addresses.map((address: any, key: number) => {
      array.push(address.state);
    })
    setStates([...new Set(array)]);
  }
  const getSelectedKey = (location: any) => {
    if (location.pathname == '/addresses') {
      setSelectedKey("all");
      if (location.search) {
        const key = location.search.substring(7);
        setSelectedKey(key);
      }
    } else {
      setSelectedKey('');
    }
  }
  useEffect(() => {
    history.listen((location: any) => {
      getSelectedKey(location);
    })
  })
  useEffect(() => {
    if (!states && addresses.length > 0) {
      getStates();
    }
  })
  useEffect(() => {
    if (promotions && promotions.length > 0) {
      setSidebarPromotions(promotions.filter((promo: any, i: number) => promo.in_addresses_sidebar));
    }
  }, [promotions])
  const getRandomPromotion = () => {
    let promotion = null;
    if(sidebarPromotions.length > 0){
      const index = Math.floor(Math.random() * sidebarPromotions.length);
      promotion = sidebarPromotions[index];
    }
    return promotion
  }
  return (
    <Drawer
      title={
        <>
          <h2>Nos adresses</h2>
          <hr />
          <p>Découvrez et choisissez parmi une multitude d’adresses de domiciliation éventuelles pour votre entreprise</p>
        </>
      }
      placement="left" onClose={onClose} visible={addresseSiderOpen} className={"addresses-sidebar"} width={screenWidth < 992 ? "80%" : 600}>
      <div className={"sidebar-wrapper"}>
        <Menu
          selectedKeys={[selectedKey]}
        >
          <Menu.Item key={"all"}>
            <Link to={"/adresses-domiciliation-tunisie"} onClick={onClose} className={"sidebar-menu-link"}>Voir toutes les adresses <ArrowRightOutlined /></Link>
          </Menu.Item>
          {states && states.length > 0 ? states.map((state: string, key: number) => (
            <Menu.Item
              key={state}
            >
              <Link
                onClick={onClose}
                className={"sidebar-menu-link"}
                to={
                  {
                    pathname: "/adresses-domiciliation-tunisie",
                    search: `?state=${state}`,
                    state: {
                      filter: state
                    }
                  }
                }
              >
                {state}
              </Link>
            </Menu.Item>
          )) : (
            <em style={{color: "#bfbfbf", textAlign: "center", display: "block", paddingTop: "15%" }}>Aucune adresse</em>
          )}
        </Menu>
        {sidebarPromotions.length > 0 ? (
          <SmallPromotionalCard
            data={getRandomPromotion()}
          />
        ) : null}
      </div>
    </Drawer>
  );
};

export default AddressesSidebar;
