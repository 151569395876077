import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Modal, Row, Col } from 'antd';
import "./promotional-cards.scss";
import { Link } from "react-router-dom";
import Counter from "../../pages/coming-soon-page/Counter";
import { SET_PROMO_SEEN } from "../../actions/ui-actions/types";
import icon1 from '../../assets/png/Saly-1.png';
import icon2 from '../../assets/png/Saly-7.png';

const BigPromotionalCard = () => {
  const promotions = useSelector((state: RootState) => state.serivcesReducer.promotions);
  const promoIsSeen = useSelector(
    (state: RootState) => state.uiReducer.promoIsSeen
  );
  const [promo, setPromo] : [any, (promo: any) => void] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const getRandomImage = () => {
    const index = Math.random() * 2;
    if (index > 1) {
      return icon1;
    }
    return icon2
  }
  const setPromoSeen = () => {
    dispatch({ type: SET_PROMO_SEEN })
    setVisible(false)
  }
  useEffect(() => {
    if (promotions && promotions.length > 0) {
      const homePromos = promotions.filter((pro: any, i: number) => pro.in_home_page);
      const index = Math.floor(Math.random() * homePromos.length);
      setPromo(homePromos[index]);
    }
  }, [promotions])
  useEffect(() => {
    if (promo) {
      setTimeout(() => {
        if (!promoIsSeen) {
          setVisible(true);
        }
      }, 5000);
    }
  }, [promo])
  if (promo && promo.id) {
    return (
      <Modal
        centered
        visible={visible}
        onCancel={setPromoSeen}
        style={{ top: 10 }}
        footer={<div></div>}
        wrapClassName={"big-promotion-card-modal"}
        width={"80%"}
      >
        <div className={"big-promotion-card-modal-content"}>
          <Row>
            <Col xs={24} md={10} className={"left"}>
              <img src={getRandomImage()} alt="img" />
            </Col>
            <Col xs={24} md={14} className={"right"}>
              <h2>Alerte promotion ! </h2>
              <div dangerouslySetInnerHTML={{ __html: promo.body }} className={"big-promotion-body"}></div>
              <Counter
                endDate={promo.expiry_date}
              />
              {promo.link ? (
                <Link to={promo.link} className="blue-btn">{promo.link_text}</Link>
              ) : null}
            </Col>
          </Row>

        </div>
      </Modal>
    )
  } else {
    return null
  }
};

export default BigPromotionalCard;
