import React from "react";
import { Carousel } from "antd";
import image from '../../assets/team/aymen.jpg'

const Team = () => {
  const team = [
    {
      id: 1,
      image: image,
      name: 'Aymen Mokadem',
      job: 'CEO'
    }
  ]
  return (
    <>
      <div className={"team-block"}>
        <div className={"max-width-container"}>
          <h2><span>Domicilier.tn</span>
            Rencontrez l'équipe derrière le succès de Domicilier
          </h2>
          <Carousel autoplay dots={true} arrows={false}>
            <div>
              <div className="team-members-wrapper">
                {team.map((member, i) => (
                  <div className="team-member-wrapper" key={i} style={{ backgroundImage: `url('${member.image}')` }}>
                    <div className={"team-member"} >
                      <div className={"info"}>
                        <strong>{member.name}</strong>
                        <span>{member.job}</span>
                      </div>
                    </div>
                  </div>
                  ))}
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Team;
