import React, { useEffect, useState } from "react";
import { Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

interface ArticlesSearchBarProps {
  search: any,
}

const ArticlesSearchBar: React.FC<ArticlesSearchBarProps> = ({ search }) => {
  const [date, setDate] = useState('today');
  const [key, setKey] : [null | string, (key: null | string) => void] = useState<null | string>(null);
  const options = [
    <Option key={1} value={"today"}>Aujourd'hui</Option>,
    <Option key={2} value={"month"}>Mois</Option>,
    <Option key={3} value={"year"}>Année</Option>,
  ];
  const changeFilter = (value: any) => {
    setDate(value);
    setKey(null);
  }
  useEffect(() => {
    if (key && key.length > 0) {
      const values = { key: key, date: date }
      search(values)
    } else {
      setKey(null)
      search(null)
    }
  }, [key || date])
  return (
    <div className={"search-bar"}>
      <Input
        placeholder="Tapez un mot-clé, un auteur, une catégorie, un hashtag"
        onChange={(e) => setKey(e.target.value.trim())}
        value={key || ''}
        addonAfter={
          <div className="addon-after">
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onChange={changeFilter}
            value={date}
          >
            {options}
          </Select>
          <div className={"search-icon"}>
            <SearchOutlined/>
          </div>
          </div>
          }
      />
    </div>
  );
};

export default ArticlesSearchBar;
