import React from "react";

const Facebook = () => {
    return (
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6043 8.00135H11.6668V6.33469C11.6668 5.47469 11.7368 4.93302 12.9693 4.93302H14.526V2.28302C13.7685 2.20469 13.0068 2.16635 12.2443 2.16802C9.9835 2.16802 8.3335 3.54885 8.3335 6.08385V8.00135H5.8335V11.3347L8.3335 11.3339V18.8347H11.6668V11.3322L14.2218 11.3314L14.6043 8.00135Z" fill="white"/>
</svg>
    );
};

export default Facebook;
