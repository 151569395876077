import React from "react";

const HeadPhonesSmallIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.08892 14.1299H5.42503C5.87505 7.79582 11.0094 2.78422 17.2564 2.78422C22.1996 2.78422 26.6727 5.9993 28.387 10.7846C28.6453 11.5057 29.4208 11.8739 30.1192 11.6073C30.8175 11.3405 31.1742 10.5397 30.9159 9.81863C29.8941 6.96647 28.0789 4.52582 25.6666 2.76049C23.1988 0.954571 20.2906 0 17.2564 0C13.3673 0 9.71088 1.5639 6.96085 4.4036C4.24668 7.20626 2.73709 10.9207 2.69813 14.878C1.08695 15.842 0 17.641 0 19.6984V24.4316C0 27.502 2.41913 30 5.39261 30H8.08892C8.8335 30 9.43707 29.3768 9.43707 28.6079V15.522C9.43707 14.7532 8.8335 14.1299 8.08892 14.1299ZM6.74076 27.2158H5.39261C3.90587 27.2158 2.69631 25.9668 2.69631 24.4316V19.6984C2.69631 18.1632 3.90587 16.9142 5.39261 16.9142H6.74076V27.2158Z"
        fill="white"
      />
      <path
        d="M29.5946 14H26.8919C26.1455 14 25.5405 14.6085 25.5405 15.3592V24.6699V28.1359V29.5631C25.5405 30.3126 24.9343 30.9223 24.1892 30.9223H20.0676V30.9654C19.6066 30.1293 18.7205 29.5631 17.7027 29.5631C16.2101 29.5631 15 30.7802 15 32.2816C15 33.7829 16.2101 35 17.7027 35C18.7205 35 19.6066 34.4338 20.0676 33.5977V33.6408H24.1892C26.4246 33.6408 28.2432 31.8115 28.2432 29.5631V29.4951H29.5946C32.5751 29.4951 35 27.0562 35 24.0583V19.4369C35 16.439 32.5751 14 29.5946 14ZM32.2973 24.0583C32.2973 25.5572 31.0849 26.7767 29.5946 26.7767H28.2432V24.6699V16.7184H29.5946C31.0849 16.7184 32.2973 17.9379 32.2973 19.4369V24.0583Z"
        fill="white"
      />
    </svg>
  );
};

export default HeadPhonesSmallIcon;
