import React from "react";

const ArrowPrev = () => {
    return (
<svg width="54" height="21" viewBox="0 0 54 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.7197 10.9426L1 10.9426" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.051 1.89174L0.999999 10.9427L10.051 19.9937" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    );
};

export default ArrowPrev;
