import React, { useEffect } from "react";
import "./help-page.scss";
import LandingFooter from "../../components/landing-footer/LandingFooter"
import FAQBlock from "./FAQBlock";
import HelpFirstBlock from "./HelpFirstBlock";
import StartDomiciliationBlock from "../../components/addresses-page-components/StartDomiciliationBlock";
import orangeEllipse from "../../assets/png/Orange-Ellipse.png";

const HelpPage = () => {
  useEffect(() => {
    document.title = "Besoin d'aide ? | Domiciliation et création d'entreprise en Tunisie";
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", "https://domicilier.tn/help");
    }
  })
  return (
      <div className="help-page">
        <HelpFirstBlock/>
        <div className={"head-and-faq-block"}>
          <FAQBlock/>
          <img src={orangeEllipse} alt="ellipse" />
        </div>
        <StartDomiciliationBlock/>
        <LandingFooter/>
      </div>
  );
};

export default HelpPage;
