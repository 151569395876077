import React from "react";
import { Layout } from "antd";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
const { Content } = Layout;

const WideLayout = ({ location, children, ...rest }: any ) => {
  return (
    <Layout style={{ minHeight: "100vh" }} className={"app-container"}>
      <Layout className="app-layout">
        <Content>
          <TransitionGroup component={"section"} >
            <CSSTransition
              key={location.key}
              timeout={450}
              classNames="fade"
              unmountOnExit
            >
              {children}
            </CSSTransition>

          </TransitionGroup>
        </Content>
      </Layout>
    </Layout>
  );
};

export default WideLayout;