import React from "react";
import JobOffersList from "../../components/job-offers-components/JobOffersList";

const JobOffersBlock = () => {
  return (
    <>
      <div className={"job-offers-block"} >
        <div className={"max-width-container"}>
          <h2>Vous souhaitez rejoindre notre équipe?
            <br />
            <span>Recherchez une offre d'emploi</span>
          </h2>
          <JobOffersList />
        </div>
      </div>
    </>
  );
};

export default JobOffersBlock;
