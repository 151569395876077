export const OPEN_ADDRESSES_SIDER = "OPEN_ADDRESSES_SIDER";
export const CLOSE_ADDRESSES_SIDER = "CLOSE_ADDRESSES_SIDER";
export const OPEN_SERVICES_SIDER = "OPEN_SERVICES_SIDER";
export const CLOSE_SERVICES_SIDER = "CLOSE_SERVICES_SIDER";
export const OPEN_DASHBOARD_SIDER = "OPEN_DASHBOARD_SIDER";
export const CLOSE_DASHBOARD_SIDER = "CLOSE_DASHBOARD_SIDER";
export const OPEN_APP_HEADER = "OPEN_APP_HEADER";
export const CLOSE_APP_HEADER = "CLOSE_APP_HEADER";
export const OPEN_APP_SIDEBAR = "OPEN_APP_SIDEBAR";
export const CLOSE_APP_SIDEBAR = "CLOSE_APP_SIDEBAR";
export const SET_PROMO_SEEN = "SET_PROMO_SEEN";
export const SET_PROMO_NOTSEEN = "SET_PROMO_NOTSEEN";
