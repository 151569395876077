/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function getAddresses(filter : string | null) {
  return axiosInstance({
    method: "get",
    url: `services/addresses`,
    params: {filter:  filter}
  });
}

function getAddressRequest(slug: string) {
  return axiosInstance({
    method: "get",
    url: `services/addresses/${slug}`,
  });
}

function getDepartmentsRequest() {
  return axiosInstance({
    method: "get",
    url: `services/departments`,
  });
}

function getJobOffersRequest(params: any) {
  return axiosInstance({
    method: "get",
    url: `services/jobs`,
    params: params
  });
}

function getJobOfferRequest(slug: string) {
  return axiosInstance({
    method: "get",
    url: `services/jobs/${slug}`,
  });
}

function getPromotionsRequest() {
  return axiosInstance({
    method: "get",
    url: `services/promotions`,
  });
}

function getAddressReviewsRequest(addressId: number) {
  return axiosInstance({
    method: "get",
    url: `services/reviews/address/${addressId}`,
  });
}

function getHomeReviewsRequest() {
  return axiosInstance({
    method: "get",
    url: `services/reviews/home`,
  });
}

function getFAQsRequest() {
  return axiosInstance({
    method: "get",
    url: `services/faqs`,
  });
}


function applyRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: `services/jobs`,
    data: body
  });
}

const Services = {
    getAddresses,
    getAddressReviewsRequest,
    getHomeReviewsRequest,
    getFAQsRequest,
    getAddressRequest,
    getJobOffersRequest,
    getJobOfferRequest,
    getPromotionsRequest,
    applyRequest,
    getDepartmentsRequest
};

export default Services;
