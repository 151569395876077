import React, { useEffect, useState } from "react";
import "./contact-page.scss";
import { Row, Form, Input, Spin, Col, message  } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axiosInstance from "../../config/axios-instance";

import TextArea from "rc-textarea";

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#0BFFDB" }} spin />;

const ContactPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    if (values) {
      setIsLoading(true);
      axiosInstance({
        method: "post",
        url: "services/contact",
        data: values,
      })
      .then((response) => {
        setIsLoading(false);
        message.success({
          content: 'Votre message a été envoyé avec succès !',
          className: 'success-message',
          duration: 3
        });
      })
      .catch ((e) => {
        setIsLoading(false);
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      })
      form.resetFields();
    }
  }
  useEffect(() => {
    document.title = `Contact | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", "https://domicilier.tn/contact");
    }
  })
  return (
    <div className={"contact-page"}>
      <div className="max-width-container">
        <div className={"content"}>
          <div className={"header"}>
            <h1 className={"white-title"}>Contactez-nous</h1>
            <hr/>
          </div>
          <p>N’hésitez pas à nous contacter pour plus de renseignements concernant le service de création d’entreprise, de domiciliation d’entreprise, de boîte postale ou encore pour obtenir plus d’informations concernant nos formules. Contactez-nous également pour connaître nos offres ou pour demander un devis personnalisé.</p>
          <div><strong>Email : </strong> <a href="mailto:contact@domicilier.tn">contact@domicilier.tn</a></div>
          <div><strong>Téléphone : </strong> <a href="phone:+21693314067">+216 93 314 067</a></div>
          <Form
              name="contact-form"
              onFinish={onFinish}
              className={"contact-form"}
              form={form}
            >
              <Row gutter={[24, 24]} justify="space-between">
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Tapez votre nom et prénom SVP!",
                      },
                    ]}
                  >
                    <Input placeholder={"Nom et Prénom"} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Tapez votre numéro de téléphone SVP!",
                        },
                      ]}
                    >
                    <Input placeholder={"Téléphone"} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]} justify="space-between">
                <Col xs={24} md={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Tapez votre email SVP!",
                      },
                    ]}
                  >
                    <Input placeholder={"Email"} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                      name="object"
                      rules={[
                        {
                          required: true,
                          message: "Tapez l'objet de votre message SVP!",
                        },
                      ]}
                    >
                    <Input placeholder={"Objet du message"} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Tapez votre message SVP!",
                      },
                    ]}
                  >
                    <TextArea style={{width: "100%"}} placeholder={"Tapez votre message"} />
                  </Form.Item>

              <button className={"contact-submit-btn"} type="submit">
                 {isLoading ? (<Spin indicator={antIcon} />) : "Confirmer"}
              </button>
            </Form>
        </div>
      </div>
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div>
  );
};

export default ContactPage;
