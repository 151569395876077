import React from "react";
import { Link } from "react-router-dom";
import {Button } from 'antd';
import moment from 'moment';
import 'moment/locale/fr'
import Timer from "../../svg-components/icons/Timer";
import Place from "../../svg-components/icons/Place";

moment.locale('fr')

interface JobOfferCardPropsTypes {
  jobOffer: any,
  apply: any
}

const JobOfferCard: React.FC<JobOfferCardPropsTypes> = ({ jobOffer, apply }) => {
  return (
    <div className={"job-offer-card-wrapper"}>
      <div>
        <div className={"job-offer-card-header"} >
          <Link to={`/career/job-offer/${jobOffer.slug}`} className={"job-offer-card-title"}>
            {jobOffer.title}
          </Link>
          <span>Publiée il y a {moment(jobOffer.created_at).fromNow(true)}</span>
        </div>
        <div className={"job-offer-card-preview"}>
          {jobOffer.preview}
        </div>
      </div>
      <div className={"job-offer-card-address"}>
        <Place/>
        <p>{`${jobOffer.address.address_line_1}, ${jobOffer.address.city} ${jobOffer.address.zip_code}, ${jobOffer.address.state}`}</p>
      </div>
      <div className={"job-offer-card-type"}>
        <Timer/>
        <p>{jobOffer.type == "parttime" ? "Temps partiel" : "Temps plein"}</p>
      </div>
      <div className={"job-offer-card-footer"}>
        <Button onClick={apply} className="blue-btn">Postuler</Button>
      </div>
    </div>
  );
};

export default JobOfferCard;
