import React from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/fr'
moment.locale('fr')

interface ArticleCardPropsTypes {
  article: any
}

const ArticleCard: React.FC<ArticleCardPropsTypes> = ({ article }) => {
  return (
    <div className={"article-card-wrapper"}>
      <div>
        <div className={"article-card-header"} style={{ backgroundImage: `url('${process.env.REACT_APP_STORAGE_URL}${article.main_picture}')` }}></div>
        <h3 className={"article-card-title"}>
          <Link to={`blog/article/${article.slug}`} >
            {article.title}
          </Link>
        </h3>
        <div className={"article-card-preview"}>
          {article.preview}
          <Link to={`blog/article/${article.slug}`} className={"see-more-btn"}>Voir plus</Link>
        </div>
      </div>
      <div className={"article-card-footer"}>
        <Avatar
          icon={<UserOutlined />}
          src={article.author.avatar ? process.env.REACT_APP_STORAGE_URL + article.author.avatar : null}
          size={30}
        />
        <p className={"author-name"}>{window.capitalize(article.author.fname) + ' ' + window.capitalize(article.author.lname)}</p>
        <div>{moment(article.created_at).format("DD MMMM YYYY")}</div>
      </div>
    </div>
  );
};

export default ArticleCard;
