import React, { useEffect, useState } from "react";
import { Image, Row, Col  } from 'antd';
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

const AddressGallery = () => {
  const address = useSelector((state: RootState) => state.serivcesReducer.address);
  const [addressPictures, setAddressPictures] : any = useState([]);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (address) {
      setAddressPictures(address.pictures_list);
    }
  }, [address])
  const getPictureURL = (i: number) => {
    if (addressPictures.length > 0) {
      return addressPictures[i].url;
    }
    return ''
  }
  return (
    <>
      <div className={"address-gallery"}>
        {address.promo ? (
          <div className={"address-promo"}>
            <span>Promo !</span>
            <span>-{address.promo} %</span>
          </div>
        ) : null}
        <Row className={"address-gallery-wrapper"} style={{ width: "100%" }} gutter={[24, 24]} justify={"space-between"}>
          {addressPictures.map((pic: any, i : number) => (
            <Col xs={24} md={i === 0 ? 24 : 12} key={i}>
              {i === 0 ? (
                <Image
                  preview={{ visible: false }}
                  src={getPictureURL(i)}
                  onClick={() => setVisible(true)}
                />
              ) : (
                <img src={getPictureURL(i)} alt="img" />
              )}
            </Col>
          ))}
        </Row>
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
            {addressPictures.map((pic: any, i : number) => (
              <Image key={i} src={getPictureURL(i)} alt="img" />
            ))}
          </Image.PreviewGroup>
        </div>
      </div>
    </>
  );
};

export default AddressGallery;
