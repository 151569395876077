import React from "react";
import domi from "../../assets/png/addresses_page_second.png";
import { Row, Col } from 'antd';


const AboutSecondBlock = () => {
  return (
    <>
      <div className={"about-second-block"}>
        <div className={"max-width-container"}>
          <h2>Les Valeurs de domicilier.tn</h2>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <div className={"content"}>
                <h3>Partage</h3>
                <p>
                  Domicilier.tn est la solution idéale qui vous permet la location d’un local sans frais et charges locatives accompagné d’un accès aux donnés et la possibilité de les partager avec les membres du cabinet.
                </p>
              </div>
              <div className={"content"}>
                <h3>Transparence</h3>
                <p>
                  Domicilier.tn offre un accès facile aux informations et une capacité à recevoir et analyser ces informations vis à vie des clients  mais aussi des collaborateurs.
                </p>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className={"image-container"}>
                <img src={domi} alt="img" />
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className={"content"}>
                <h3>Solidarité</h3>
                <p>
                  Domicilier.tn c’est un service mais avant tout une famille solidaire où chacun exécute sa tâche avec méticulosité afin de faciliter l’achèvement de votre projet.
                </p>
                <p>Ensemble partant du département marketing, passant par le conseil commercial jusqu’à l’arrivée pour le service client  et surtout tout est fait sur le même toi.</p>
              </div>
              <div className={"content"}>
                <h3>Crédibilité</h3>
                <p>
                  Nous avons développé des produits personnalisés dans le but de permettre aux startups tunisiens et étrangers  de mettre en marche leur activité rapidement dans les meilleures conditions possibles avec des prix compétitifs.
                </p>
                <p>Domicilier.tn est votre centre d’intérêt de confiance.</p>
              </div>
              <div className={"content"}>
                <h3>Innovation</h3>
                <p>
                  Domicilier.tn une nouvelle idée implantée en Tunisie 100% en ligne qui offre des services exclusifs pour répondre aux problèmes des startups à haute intensité technologique.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AboutSecondBlock;
