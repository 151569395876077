import React from "react";
import domi from "../../assets/png/Saly-36.png";
import lightEllipse from "../../assets/png/Light-Ellipse.png";


const CareerFirstBlock = () => {
  return (
    <>
      <div className={"career-first-block"} style={{ backgroundImage: `url('${domi}')` }}>
        <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"} />
        <div className={"max-width-container"}>
          <div className={"content"}>
            <h1><span>Rejoignez domicilier.tn</span> <br />
Nous sommes toujours à la recherche de la perle rare.
Relevez le challenge
et rejoignez nous.</h1>
          </div>
          <div className={"mobile-image-container"}>
            <img src={domi} alt="img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerFirstBlock;
