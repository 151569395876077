import React from "react";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import domi from "../../../assets/png/Dashboard.png";
import lightEllipse from "../../../assets/png/Light-Ellipse.png";


const ServiceFirstBlock = () => {
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.isLoggedIn);
  return (
    <>
    <div className={"service-first-block"} style={{backgroundImage: `url('${domi}')`}}>
      <img src={lightEllipse} alt="Light Ellipse" className={"ellipse-right"}/>
      <div className={"max-width-container"}>
        <div className={"content"}>
            <h1>Plus de services, plus de succès !</h1>
            <p>Après l’adresse, il y a les services ! <br /> Nous assurons la gestion de votre activité quotidiennement.</p>
            {isLoggedIn ? null : (
              <div className={"buttons-wrapper"}>
                <a href={process.env.REACT_APP_APP_URL + "/signup"} className={"blue-btn"}>Commencer ma domiciliation</a>
                <Link to={"/contact-me"} className={"blue-btn"}>Echanger avec un conseiller</Link>
              </div>
            )}
            <div className={"mobile-image-container"}>
              <img src={domi} alt="img" />
            </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ServiceFirstBlock;
